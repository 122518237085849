import { url_update_productos, proxyurl } from './url';

const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE");

export async function updateProductos() {

    let myInit = {
        method: 'PUT',
        headers: {
            "Ocp-Apim-Subscription-Key": "ef65b27751dd428ca0afe923f5f7f1d0"
        }
    }

    if (producionDevBool !== true) {

        myInit = {
            method: 'PUT',
            headers: {
                "Ocp-Apim-Subscription-Key": "6639ec4d5bdf4f6cbfd6b0fb91324b81"
            }
        }
    }
    try {
        let response = await fetch(proxyurl + url_update_productos, myInit);
        if (response.ok) {
            return "Se actualizaron correctamente los productos/proveedores";
        } else {
            throw "Fallo la actualización de los productos/proveedores";
        }
    } catch (error) {
        throw "Fallo la actualización de los productos/proveedores";
    }
}



export default { updateProductos };