import React, {Component} from 'react';
import "./Main.css";
import logo from './../../Imagenes/logo2.png';
import { Col, Row, Grid } from 'react-flexbox-grid';
import Body from './body/index';
import Button from '@material-ui/core/Button';
import { updateProductos } from '../../obtencionDatos/updateProductos';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


class Main extends Component {

    state = { 
        width: window.innerWidth,
        height: window.innerHeight,
        buttonEnabled: true,
        openAlert: false,
        alertMessage: "",
        alertSeverity: "success"
    };

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }
    goToLogin(){
        let {history} = this.props;
        history.push("/login");
    }

    onUpdateButtonClick(){
        this.setState({...this.state, buttonEnabled : false});
        updateProductos()
        .then(mensaje => {
            this.setState({
                ...this.state,
                buttonEnabled : true,
                alertMessage: mensaje,
                alertSeverity: "success",
                openAlert: true
            });
        })
        .catch(error => {
            console.log(error);
            this.setState({
                ...this.state,
                buttonEnabled : true,
                alertMessage: error,
                alertSeverity: "error",
                openAlert: true
            });
        }); 
    }

    handleOnCloseAlert(event, reason){
        if (reason === 'clickaway') {
            return;
        }
        this.setState({...this.state, openAlert: false});
    }

    render() {
        // FIREBASE docs https://firebase.google.com/docs/auth/web/auth-state-persistence?hl=es-419
        // Si no estamos logueados nos envia al login, logedIn puede variar bien si se cierra session
        // o tambien si expira la cookie de la session actual.
        if(this.props.logedIn === false){
            this.goToLogin();
        }

       const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE");
       let titulo = producionDevBool !==true ? "Imputación de gastos":"Imputación de gastos (dev)";
       
        return(

        <Grid fluid className="padding2" style={{paddingLeft: "0px", paddingRight: "0px"}}>
            <Row>
                <Col xs={12} className="padding">
                <div className="header">
                    <div>
                        <img src={logo} alt="logo" className="logo"></img>
                    </div>
                    <div className="contendorTittle">
                        <h2 className="Tittle">{titulo}</h2>
                    </div>
                <Button 
                variant="outlined"
                disabled={!this.state.buttonEnabled}
                onClick={this.onUpdateButtonClick.bind(this)}
                style={
                    {
                        marginRight: "15px",
                        color: "white",
                        height: "min-content",
                        alignSelf: "center",
                        backgroundColor: this.state.buttonEnabled ? "#e00c26" : "#919191" 
                        }}>
                    Actualizar productos/proveedores
                </Button>
                <Snackbar open={this.state.openAlert} autoHideDuration={6000} onClose={this.handleOnCloseAlert.bind(this)}>
                    <Alert onClose={this.handleOnCloseAlert.bind(this)} severity={this.state.alertSeverity}>
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
                </div>
                </Col>  
            </Row>
            <Body name={this.props.name} email={this.props.email}/>
         </Grid>   
        );     
    }
}

export default Main;