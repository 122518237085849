import React from 'react';
import { Col, Row} from 'react-flexbox-grid';
import Paper from '@material-ui/core/Paper';
import TipoDocContainer from './tipoDoc/TipoDocContainer';
import CotizacionContainer from './cotizacion/CotizacionContainer';
import BotonSend from './Boton';
import NewTable from './CentroCostos/NewTable';
import Respuesta from './Respuesta/Respuesta'
import './style.css';
import './styleOpacity.css';




function Body(props) {

    
    const [precioDolar,setPrecioDolar] = React.useState("");
    const [precioPesos,setPrecioPesos] = React.useState("");
    const [precioEuro,setPrecioEuro] = React.useState("");
    const [listaDocs,setListaDoc] = React.useState([]);
    const [centrosSelected,setCentrosSelected] = React.useState([]);
    const [selectedDocs,setSelectedDocs] = React.useState([]);
    const [tituloBoton, setTituloBoton] = React.useState("Siguiente");
    const [collectedData, setCollectedData] = React.useState(null);
    const [stateBoton, setStateBoton] = React.useState(1);
    const [tableState, setTableState] = React.useState(null);
    const [enable, setEnable] = React.useState({
        "1":true,
        "2":true,
        "3":true,
    });
    const [listaMonedas, setMonedas] = React.useState({
        "dolar":false,
        "euro":false,
        "peso":false,
    });
    const [statusOpacity, setStatusOpacity] = React.useState({
        box3:"boxOpacity3",
        box4:"boxOpacity4",
        box5:"boxOpacity5",
        contador:2,
    })


    const getDateTable = (date) => {
        
        let auxMonedas = listaMonedas;
        let centros = [];
        
        let listaDocAux = [];
        
        listaDocAux["fc"] = 0;
        listaDocAux["ag"] = 0;

        date.map((element)=>{
        
            const {fc,ag,moneda,name} = element;
            
            centros.push(name);
            
            moneda.map((cotizacion)=>{
                

                switch(cotizacion){

                    case "Pesos":
                    auxMonedas.peso = true;
                    break;

                    case "Dolar":
                        auxMonedas.dolar = true;
                   
                    break;
                    case "Euro":
                        auxMonedas.euro= true;
                    break;

                }
            });

            listaDocAux["fc"] = fc + listaDocAux["fc"];
            listaDocAux["ag"] = ag + listaDocAux["ag"];
          

        });

       
        setMonedas(auxMonedas);
        setCentrosSelected(centros);



        //habilita o deshabilita el boton
        console.log("fc: " + listaDocAux["fc"]);
        console.log("fc: " + listaDocAux["ag"]);
        

        // Si hay documentos agrega flags para seguir el proceso
        if(listaDocAux["fc"] > 0 ){
            listaDocAux.push("fc"); 
        }
        if(listaDocAux["ag"] > 0 ){
            listaDocAux.push("ag"); 
        }
        

        setListaDoc(listaDocAux);
    }

    

    function transformNumber(number){

        const regex = /,/g;
        let parseAString = number.toString();
        let transform = parseAString.replace(regex,".");
        let parseAInt = parseFloat(transform);
      

        return parseAInt;

    }

    function getData() {

        let cot =[];

        if(precioDolar !== ""){
            cot.push({
                moneda: "dolar",
                valor: transformNumber(precioDolar),
            });
        }
        if(precioEuro !== ""){
            cot.push({
                moneda: "euro",
                valor: transformNumber(precioEuro),
            });
        }
        if(precioPesos !== ""){
            cot.push({
                moneda: "pesos",
                valor: precioPesos
            });
        }

        let data = {
            centrosDeCosto: centrosSelected,
            FC: selectedDocs.fc,
            AG: selectedDocs.ag,
            cotizaciones: cot,
            email:props.email,
            nombre:props.name,
        };
        
        return data;
    }

    // console.log("data: " + props.userData.e);


    function handleOpacity() {

       
        let {contador} = statusOpacity;

        if(contador === 2){
            getDateTable(tableState);
        }

        if(contador === 3){
            setTituloBoton("Enviar");
            
        }

        if(contador === 4){
            let dataEnv = getData();
            
            setCollectedData(dataEnv);
        }

        if(listaMonedas.dolar === false && listaMonedas.euro === false && listaMonedas.peso === false){
            
            let box = `box${contador + 2}`;
    
            setStateBoton(stateBoton + 2);
            setStatusOpacity({ 
                ...statusOpacity,
                    [box]: box,
                    contador: contador + 2,

                    });
    
        }else {

            let box = `box${contador + 1}`;
    
            setStateBoton(stateBoton + 1)
            setStatusOpacity({ 
                ...statusOpacity,
                 [box]: box,
                 contador: contador + 1,
    
            });
        }

        
    }

    
    function handlePrecio(datosPrecio) {

        let { name, valor} = datosPrecio;
        
        switch(name)
        {
            case 'precioDolar':
                setPrecioDolar(valor);
               
                break;
            case 'precioPesos':
                setPrecioPesos(valor);
                

                break;
            case 'precioEuros':
                setPrecioEuro(valor);
                

                break;
            default:
               
        }
        
    }

    const setEnableButton = ( valor, estado )=>
    {
        setEnable({
            ...enable,
            [valor]:estado,    
            })
    }


  //Controlador de activacion del boton
    const disableBoton = () => {
        
    
        if(stateBoton === 1){

            return enable[1];
        
        } else if( stateBoton === 2 ){

            return enable[2];

        } else if (stateBoton === 3){
            
            return enable[3];
        }
        
    };

    

    React.useEffect(() => {

        if(listaMonedas.dolar === true && listaMonedas.euro === true){

            if(precioDolar !== "" && precioEuro !== ""){
         
                setEnableButton(2, false);
            }else{
         
                setEnableButton(2, true);
      
            }
        }else{

            if(listaMonedas.dolar === true){

                if(precioDolar !== ""){

                    setEnableButton(2, false);

                }else{

                    setEnableButton(2, true);
                }

            }else{

                if(precioEuro !== ""){

                    setEnableButton(2, false);

                }else{

                    setEnableButton(2, true);
                }
            }

        }
          
         
        }, [precioDolar,precioPesos,precioEuro])


            React.useEffect(()=>{

                if(selectedDocs.fc === true || selectedDocs.ag === true){
                  
                    setEnableButton(3, false); 

                } else{

                    setEnableButton(3, true);
                }

            },[selectedDocs])

        function MostrarResultados()
        {
            if(stateBoton>3){

               return (<Respuesta info={collectedData} />);

            }else{

                if(disableBoton()){

                    return(<BotonSend onClick={handleOpacity} titulo = {tituloBoton} disable={true}></BotonSend>);
                }else{
                    
                    return(<BotonSend onClick={handleOpacity} titulo = {tituloBoton} disable={false}></BotonSend>);
                }
               
                    }
        }

        console.log(props.email);
        console.log(props.name);
 
    return(
        <div>
            <Row>
                <Col xs={12} lg={6}>
                    <div className="box1">
                        <label >Seleccione las planillas correspondientes</label>
                        <Paper elevation={3} className="tablaNew" >
                                
                             <NewTable  enable={stateBoton} setEnableButton={setEnableButton} getdate={(e)=>setTableState(e)}/> 

                        </Paper>
                    </div> 
                </Col>

                <Col xs={12} lg={2}>
                    <div className="box35">
                            
                            <CotizacionContainer stateModulo={stateBoton} listamon={listaMonedas} listadoc={listaDocs} statusClass={statusOpacity.box3} precioDolar={precioDolar} precioPeso={precioPesos} PrecioEuro={precioEuro} handle={handlePrecio } />            
                     
                     </div>
                    
                </Col>

                <Col xs={12} lg={4}>
                    <div className={statusOpacity.box4}>
                        <label>¿Qué tipo de documento quiere procesar?</label>
                        <TipoDocContainer stateModulo={stateBoton} lista={listaDocs} getDocs={(e) => setSelectedDocs(e)} />
                    </div>
                </Col>
            </Row>

            <Row center="lg">
                <Col xs={12} lg={4}>
                     
                    {MostrarResultados()}
                    
                </Col>
            </Row>

        </div>
    );
}

export default Body;