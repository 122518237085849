import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Refresh} from '@material-ui/icons';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {getCC} from '../../../../obtencionDatos/centrosDeCosto';
import IconButton from '@material-ui/core/IconButton';
import "./styleTable.css";

function createData(name, fc, ag, moneda, link) {
  return { name, fc, ag, moneda, link };
}
function compareTotal(a,b){
    if ((a.fc + a.ag) > (b.fc + b.ag)) {
      return -1;
  }
  if ((a.fc + a.ag) < (b.fc + b.ag)) {
      return 1;
  }
  return 0;
}

function descendingComparator(a, b, orderBy) {

  if(orderBy === "total"){
    return compareTotal(a,b);
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Seleccionar todos' },
  { id: 'Facturas de compra', numeric: true, disablePadding: false, label: 'Facturas A' },
  { id: 'ag', numeric: true, disablePadding: false, label: 'Asiento Genéricos' },
  
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="ancho">
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Centro de Costos
        </Typography>
      )}

     
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .ancho':{
     
    } ,
    width: '100%',
    
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection:"column",
    position: "relative"
  },
  table: {
    maxHeight:500,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container:{ 

    maxHeight:240
  }
}));

export default function NewTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('total');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(9);
  const [filas, setFilas] = React.useState([]);
  const [CC, setCC] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {

    getCC().then(centros=>{
      setLoading(false);
      setCC(centros);
      
      setRowsPerPage(centros.map((name)=>name).length);
      
    }).catch(error=>{
      // TODO AGREGAR SETROWS VACIO 
    })
   
  }, [])

  
//suma la cantidad de documentos a procesar para el control del avanzado de etapa
  React.useEffect(() => {

    let listaDocAux = [];

    listaDocAux["fc"]= 0;
    listaDocAux["ag"]= 0;

    filas.map((elem) => {

      const {fc,ag} = elem;

      listaDocAux["fc"] = fc + listaDocAux["fc"];
      listaDocAux["ag"] = ag + listaDocAux["ag"];

    })

  if(listaDocAux["fc"] > 0 || listaDocAux["ag"] > 0){
    props.setEnableButton(1, false);
    
  }else{
    props.setEnableButton(1, true);

  }
   
  }, [selected])


  //funcion que actualiza la tabla
  const Actualizar = ()=>{

    setCC([]);
    setLoading(true);

    getCC().then(centros=>{
      setLoading(false);
      setCC(centros);
     
      setRowsPerPage(centros.map((name)=>name).length);
      
    }).catch(error=>{
      
    })


  }


  // setRowsPerPage(datos.length);

  const rows = CC.map(( dato )=>{

    let { cc,link,cantidadFC,cantidadAG,monedas} = dato;
    return createData(cc, cantidadFC, cantidadAG, monedas, link);

  }

  ) ;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      const newFilas = rows.map((f) => {
        
        return f;
      });
      
      props.getdate(newFilas);
      setFilas(newFilas);
      setSelected(newSelecteds);
      return;
    }
    
    setFilas([])
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const {name} = row;
    // console.log(row);
    const selectedIndex = selected.indexOf(name);//guarda en que posición se encuentra el el elemento seleccionado
    let newSelected = [];//se crea un un array donde se guardaran los items seleccionados
    let newfilas = [];

    if (selectedIndex === -1) {   //Si el resultado es igual a -1, el item seleccionado no se encuentra en la nueva lista, por ende se agrega
      newSelected = newSelected.concat(selected, name);
      newfilas = newfilas.concat(filas,row);
    } else if (selectedIndex === 0) { //si el elemento ya se encuentra en la lista se toman todos los elementos excluyendo el primero y se guarda en la nueva lista
      newSelected = newSelected.concat(selected.slice(1));
      newfilas = newfilas.concat(filas.slice(1));
    } else if (selectedIndex === selected.length - 1) { //si el resultado es igual al longitud del array menos uno, se guardara desde el primer hasta el penultimo elemento
      newSelected = newSelected.concat(selected.slice(0, -1));
      newfilas = newfilas.concat(filas.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
        
        
        newfilas = newfilas.concat(
        filas.slice(0, selectedIndex),
        filas.slice(selectedIndex + 1),
      );

    }
    
    setFilas(newfilas);
    setSelected(newSelected);
    props.getdate(newfilas);
    
    
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    
    <div className={classes.root}>
    
      <Paper className={classes.paper}>
       
        {loading ?  (
          <div style={{position: "absolute", display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
            <div>Cargando</div>
            <CircularProgress  color="secondary" size={24} style={{marginLeft: "10px"}}/>
          </div>) : null}
        
        <TableContainer className={classes.container}>
          
          <Table
            
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              disabled={props.enable>1?true:false}
              onSelectAllClick={ props.enable > 1 ? () => {}  : handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody className="ScrollTable">
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  { if(props.enable>1){

                    

                    return( <TableRow
                      hover
                      disabled
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.fc}</TableCell>
                      <TableCell align="right">{row.ag}</TableCell>
                     
                    </TableRow>
                    );
          

                  }else{
                    return( <TableRow
                      hover
                     
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <a className="linkSin"
                          href={row.link}
                          onClick={(e) =>
                              e.stopPropagation()
                          }
                          target="_blank"
                      >{row.name}</a>
                      </TableCell>
                      <TableCell align="right">{row.fc}</TableCell>
                      <TableCell align="right">{row.ag}</TableCell>
                      
                    </TableRow>);
                  }}

                  
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
       
      <IconButton  label="recargar paginaa" color="secondary">
        <Refresh onClick={Actualizar}/>
      </IconButton>
      </Paper>
        
    </div>
    
  );
}
