import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export default function CheckboxLabels(props) {
 

   function changeStatus(event) {
    const target = event.target;
    const name = target.name;
    const checked = target.checked;
    // console.log(name);
    // console.log(checked);

    props.onchange({
      name, 
      checked,
    });
   }

  return (
   
        <FormControlLabel
          control={<Checkbox disabled={props.disabled} checked={props.checked} onChange={changeStatus} name={props.name} />}
          label={props.label}
        />
  );
}