const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE" );

let myInit = {
    method: 'GET',
    headers: {
        "Ocp-Apim-Subscription-Key":
        "ef65b27751dd428ca0afe923f5f7f1d0"
    },
};

if(producionDevBool !== true){

    myInit = {
        method: 'GET',
        headers: {
            "Ocp-Apim-Subscription-Key":
            "6639ec4d5bdf4f6cbfd6b0fb91324b81"
            },
        }
}

export {myInit};