const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE")

let proxyurl = "https://cors-anywhere.herokuapp.com/";
let url_send_data = "https://snoopgateway.azure-api.net/imputaciongastos-dev/api/Planillas/Imputar";
let new_url_api = "https://snoopgateway.azure-api.net/imputaciongastos-dev/api/Planillas/get";
let url_update_productos = "https://snoopgateway.azure-api.net/imputaciongastos-dev/api/Planillas/ActualizarProductosYProveedores";
console.log(process.env.REACT_APP_PRODUCCION);

if (producionDevBool !== true) {
    proxyurl = "https://cors-anywhere.herokuapp.com/";
    url_send_data = "https://snoopgateway.azure-api.net/imputaciongastos/api/Planillas/Imputar";
    new_url_api = "https://snoopgateway.azure-api.net/imputaciongastos/api/Planillas/Get";
    url_update_productos = "https://snoopgateway.azure-api.net/imputaciongastos/api/Planillas/ActualizarProductosYProveedores";
}

export { proxyurl };
export { url_send_data };
export { new_url_api };
export { url_update_productos };