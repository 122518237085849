import React from 'react';
import { Paper } from '@material-ui/core';
import CheckboxLabels from './TipoDoc';


const TipoDocContainer = (props) => {
  

    const [state, setState] = React.useState({
        fc: false,
        ag: false,
      });
      
      
      React.useEffect(() => {

        props.lista.map((elem)=>{
          switch(elem)
          {
            case "fc":
              setState({
                ...state,
                    fc:true
              });
              break;

            case "ag":
              setState({
                ...state,
                ag:true
              });
          }
        })
       
        props.getDocs(state);
      },[]);  

      const handleChange = (datoStatus) => {

        let {name, checked} = datoStatus;
        // console.log(name);
        // console.log(checked);
        setState({ ...state, [name]:checked });
        props.getDocs({...state, [name]:checked});
      };

      const listaDoc = props.lista;

      // console.log(listaDoc);
      const listaDocumento = listaDoc.map((element) => {
      
        // console.log("doc Container");
        // console.log(state);
        // console.log(props.stateModulo);

      if(props.stateModulo>3){
      
      return( <CheckboxLabels 
        disabled={true}
        key={element}
        checked={element === "fc" ? state.fc:state.ag} 
        onchange={handleChange}
        name={element}
        label={element === "fc" ? "Factura de Compra":"Asiento Genérico"}
      />)
      }else{
        
        return( <CheckboxLabels 
        key={element}
        disabled={false}
        checked={element === "fc" ? state.fc:state.ag} 
        onchange={handleChange}
        name={element}
        label={element === "fc" ? "Factura de Compra":"Asiento Genérico"}
        />)
      }
        })
      

    return(

        <Paper elevation={3}>
                
            {listaDocumento}

        </Paper>
    );
}

export default TipoDocContainer;