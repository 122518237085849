import { new_url_api } from './url';
import { myInit } from './myInit';


export async function getCC() {


        let response = await fetch(new_url_api, myInit);
        let respJSON = await response.json();
        return respJSON;


}


export default { getCC };