import React from 'react';
import { FormControl, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '90%',
      },
    },

    marginActivado: {
        width:"100%",
        marginTop: 10,
    },

    marginDesactivado: {
        width:"100%",
        marginTop:0,
    },

  }));



const Cotizacion = (props) => {

    

    const handlePrecio = (event) =>{

        const target = event.target;
        const value = target.value;
        const name = target.name;
        props.value(value,name);
    
      }

    const classes = useStyles();

    //   console.log(props.disabled);
    let marginClass = props.index === 0? classes.marginDesactivado:classes.marginActivado; 
    if(props.disabled>2){

        return(

            <Paper elevation={3} className={marginClass}>
    
                <FormControl className={classes.root} noValidate autoComplete="off">
                    <div>
                        
                        <TextField disabled={true} id="filled-disabled" label={props.titulo} name={props.name} onChange={handlePrecio} variant="filled"/>
            
                    </div>
               </FormControl>                    
            </Paper>
            
        );
    }else{

        return(

            <Paper elevation={3} className={marginClass}>
    
                <FormControl className={classes.root} noValidate autoComplete="off">
                    <div>
                        
                        <TextField disabled={false} id="filled-disabled" label={props.titulo} name={props.name} onChange={handlePrecio} variant="filled"/>
            
                    </div>
               </FormControl>                    
            </Paper>
            
        );
    }

    // return(

    //     <Paper elevation={3} className={classes.paper}>
    //                 {/* <FormControl className={classes.cotizacionesStyle}>
    //                     <InputLabel className={classes.label} htmlFor="my-input"> {props.titulo}: </InputLabel>
    //                     <Input className={classes.inputStyle} id="my-input" aria-describedby="my-helper-text" name={props.name} onChange={ handlePrecio } disabled/>
    //                     <FormHelperText className={classes.inputStyle} id="my-helper-text">Ingrese cantidad</FormHelperText>
    //                 </FormControl> */}

    //         <form className={classes.root} noValidate autoComplete="off">
    //             <div>
    //                 {
    //                     if(props.disabled>=2){
    //                         return ( <TextField disabled={true} id="filled-disabled" label={props.titulo} name={props.name} onChange={handlePrecio} variant="filled"/>);
    //                     }
    //                 return(<TextField
    //                     disabled={false}
    //                     id="filled-disabled"
    //                     label={props.titulo}
    //                     name={props.name}
    //                     onChange={handlePrecio}
    //                     variant="filled"
    //                 />)}
    //             </div>
    //        </form>                    
    //     </Paper>
        
    // );
} 


export default Cotizacion;