import React from 'react';
import Cotizacion from './Cotizacion';

const CotizacionContainer = (props) => {

    const handle = (valor, name) => {

        props.handle(
            {
                valor,
                name,
            }
        );
    }
    
    const stateModulo = props.stateModulo;
    const listaMonedas = props.listamon;
    const listaDocs = props.listadoc;

    function getMonedas(lista){

        // console.log(lista);

        let salida =[];
        if(lista.peso){
            salida.push("Pesos")
        }

        
        
        if(lista.dolar){
            salida.push("Dolar")
        }

        
        
        if(lista.euro){
            salida.push("Euros")
        }
        // console.log(salida);
        return salida;
        
    }

    function mostrarCotis (listaDocs){

            // console.log("dentor de contraine coti")
            // console.log(stateModulo);
         


            const listaCotizaciones = getMonedas(listaMonedas).map( (element,index) => <Cotizacion index={index} key={element} titulo={element} name={`precio${element}`} value={handle} disabled={stateModulo}/>)  
            return(listaCotizaciones);
    

    
    };

    return(
        <div className={props.statusClass}>
        <label>Cotización</label>
           
            {mostrarCotis(listaDocs)}
        </div>
        
    );
}

export default CotizacionContainer;

