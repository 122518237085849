import React, {Component} from 'react';
import * as firebase from 'firebase/app';
import Button from '@material-ui/core/Button';
import logo from './../../Imagenes/logo2.png';
import './inicio.css';




class Login extends Component {

    constructor() {
        super();
        this.state = {
            user:null
        };

    this.handleAuth = this.handleAuth.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.pruebas=this.pruebas.bind(this);
    }

    componentDidMount () {
        firebase.auth().onAuthStateChanged(user => {
            if(user !== null){
                this.setState({user},() => {
                    this.props.setLogedIn(true);
                    this.goToMain();
                    // console.log(user.email);
                    this.props.getEmail(user);
                });
            }else{
                this.setState({user},() => {
                    
                    this.props.setLogedIn(false);
                });
            }
        });
    }

    handleAuth() {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithPopup(provider)
        .then(result => {

            this.props.getEmail(result.user.email);
            console.log(`${result.user.email} ha iniciado sesion`)
        }
            )
        .catch(error => console.log(`error ${error.code}: ${error.message}`));
    }

    handleLogout() {
        firebase.auth().signOut()
        .then(result => console.log(`${result.user.email} ha salido`))
        .catch(error => console.log(`error ${error.code}: ${error.message}`));
    }
    // TODO  Quitar prueba 
    pruebas() {
        console.log("funcionasss");
    }

    goToMain(){
        let { history } = this.props;
        history.push("/main");
    }


    renderLoginButton() {
        //si el usuario esta logeado
        if(this.state.user){
            return(
                <div>
                    <button onClick={this.handleLogout}>salir</button>
                </div>
            );
        }
        //y si no lo esta
        else {
            return(

        <div className="container-inicio">
            <div className="logo-box">
                <img src={logo} alt="logo" className="logo"></img>
            </div>


            <div className="box">
                <h1>Imputación de gastos</h1>
                <div className="btn"></div>
                <Button variant="contained" onClick={this.handleAuth}>Iniciar sesión con Google</Button>
            </div>

        </div>
            );  
        }
    }

    render() {
        return (
            <div>
                {this.renderLoginButton()}
            </div>
        );
    }
}

export default Login;
