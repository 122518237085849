import React from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sendData } from '../../../../obtencionDatos/postSheet';
import "./style.css";

function Respuesta(props){

    const [respuesta, setRespuesta] = React.useState(null);
    
    

    React.useEffect(() => {

        if(respuesta === null && props.info !== null)
        {
          // setLoading(true);  
          sendData(props.info).then(info=>{  
          setRespuesta(info);
          console.log("repuesta positiva",info);
         

        })
        .catch(error=>{

        console.log("respuesta error",error);

        })
        }
      }, [props.info]);
      

    if(respuesta){

      if( (respuesta >= 500 && respuesta < 600) || (respuesta >= 300 && respuesta < 400) || (respuesta >= 400 && respuesta < 500)){

        return(
          <label>Se produjo un error
            <Paper>
                <div>
                    <ul>
                        <li>hubo un error {respuesta}</li>
                        <li>comuniquese con sistemas internos</li>
                    </ul>
                </div>
            </Paper>
          </label>
        );


      }else{
          const {resultado, linkDrive, facturasCreadas, asientosCreados, detalles} = respuesta;

          if(resultado){
            
            return(
              <label>Se finalizo el proceso correctamente
                <Paper>
                    <div>
                        <ul>
                            <li>Facturas A procesadas: {facturasCreadas}</li>
                            <li>Asientos genéricos procesados: { asientosCreados }</li>
                        </ul>

                        {asientosCreados === 0 ? "":<a className="descargarExcel" href={linkDrive}> DESCARGAR EXCEL </a>}
                    </div>
                </Paper>
              </label>
            );

          }else{

            return(
              <label>Se produjo un error
                <Paper>
                    <div>
                        <ul>
                            <li>detalles: { detalles }</li>
                        </ul>

                      
                    </div>
                </Paper>
              </label>
            );

      }
    }
      // !!
    }else{
      return(
        <Paper>
            <div>
              <div>Procesando</div>
              <CircularProgress  color="secondary" size={24} style={{marginLeft: "10px"}}/>
            </div>
        </Paper>
      )
    }
    
}

export default Respuesta;