import { url_send_data, proxyurl } from './url';

const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE");

export async function sendData(dat) {

    let myInit = {
        method: 'POST',
        body: JSON.stringify(dat),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            "Ocp-Apim-Subscription-Key": "ef65b27751dd428ca0afe923f5f7f1d0"
        }
    }
    if (producionDevBool !== true) {


        myInit = {
            method: 'POST',
            body: JSON.stringify(dat),
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Ocp-Apim-Subscription-Key": "6639ec4d5bdf4f6cbfd6b0fb91324b81"
            }
        }
    }

    let response = await fetch(proxyurl + url_send_data, myInit);
    let respJSON = await response.json();
    let estado = response.status;
    console.log("status" + estado);
    console.log("JSON " + respJSON);





    if (estado === 200) {

        console.log(respJSON);
        return respJSON;

    } else if (estado === 500) {


        return estado;
    } else if (estado === 400) {
        return estado;
    }



}



export default { sendData };