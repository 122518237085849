const producionDevBool = (process.env.REACT_APP_PRODUCCION === "FALSE" )


let config = {
  apiKey: "AIzaSyAjTAM99zmj1Y1Aux3_X1A_80-f-C79OQg",
  authDomain: "dev-imputaci.firebaseapp.com",
  databaseURL: "https://dev-imputaci.firebaseio.com",
  projectId: "dev-imputaci",
  storageBucket: "dev-imputaci.appspot.com",
  messagingSenderId: "820326654861",
  appId: "1:820326654861:web:4d813f82d630e71dc7d3c4"
  }


if(producionDevBool !== true){
  config = {
    apiKey: "AIzaSyBztyyfIQRNllljf-jBMbWbhi4I7XHo4SE",
    authDomain: "imputacion-de-gastos.firebaseapp.com",
    databaseURL: "https://imputacion-de-gastos.firebaseio.com",
    projectId: "imputacion-de-gastos",
    storageBucket: "imputacion-de-gastos.appspot.com",
    messagingSenderId: "138457843084",
    appId: "1:138457843084:web:1186a55bcdcb1a3ccb42a9"

  }

}

export default config;