import React, {Component} from 'react';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Login from './components/Login/Login';
import Main from './components/Main/Main';
import * as firebase from 'firebase/app';

class App extends Component {

  constructor(props){
    super(props);

   
    this.state = {
      logedIn: true,
      email:null,
    }
    this._stopAuthListener = null;

  }


  componentDidMount(){
    // Setea un listener que recibe el usuario actual, si es null es porque no hay ningun usuario logueado
    // Utiliza una session creada por firebase para saber si ya hay un usuario logueado anteriormente lo mantiene 
    // entre sesiones con un storage local manejado por el sdk.
    this._stopAuthListener = firebase.auth().onAuthStateChanged(user => {
      if(user === null){

        this.setState({
          logedIn: false,
          email: null,
        });
      }else{
        this.setState({
          logedIn: true,
          name:user.displayName,
          email:user.email,
        });
      }
  });
  }

  componenetDidUnmount(){
    // Elimina el listener al desmontar.
    this._stopAuthListener();
  }

  getEmail(email){
    this.setState({
      email,
    }
    )
  }

  setLogedIn(){
    this.setState({
      logedIn: true
    });
  }

  render() {

    console.log(this.state.email);
    console.log(this.state.name);
    return (
    <BrowserRouter>
        <Switch>
          <Route
          path="/login"
          render={ (props) => <Login {...props} setLogedIn={this.setLogedIn.bind(this)} getEmail={this.getEmail.bind(this)}/>}/>

          <Route
          exact
          path="/main"
          render={(props) => <Main {...props} logedIn={this.state.logedIn} name={this.state.name} email={this.state.email}/>} />

          <Route path="/" render={() => <Redirect to="/login"/> } />

        </Switch>
    </BrowserRouter>
    );
  }
}

export default App;
