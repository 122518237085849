import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function BotonSend(props) {
  const classes = useStyles();


  if(props.disable){
    return (
      <div>
        {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
        <Button
          onClick={props.onClick}
          variant="contained"
          color="secondary"
          className={classes.button}
          endIcon={<Icon>send</Icon>}
          disabled
        >
          {props.titulo}
        </Button>
        
      </div>
    );
  }else{

  return (
    <div>
      {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
      <Button
        onClick={props.onClick}
        variant="contained"
        color="secondary"
        className={classes.button}
        endIcon={<Icon>send</Icon>}
        
      >
        {props.titulo}
      </Button>
      
    </div>
    );
  }
}